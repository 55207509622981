<template>
  <div
    class="flex flex-row w-full bg-gradient-to-b bg-opacity-50 from-black h-20 text-white"
  >
    <div
      class="flex flex-row items-center justify-evenly w-full max-w-screen-xl mx-auto h-full"
    >
      <!-- Brand Starts -->
      <nuxt-link
        :to="{ name: 'index' }"
        class="flex flex-col w-6/12 pl-2 items-start justify-center cursor-pointer"
      >
        <NuxtImg
          src="/images/main/logo_inv.png"
          alt=""
          class="w-24 md:w-30"
        />
        <p
          class="text-sm mx-auto font-semibold tracking-widest"
        ></p>
      </nuxt-link>
      <!-- Brand Ends-->
      <!-- Right Extra Section -->
      <div
        class="flex flex-row-reverse w-5/12 items-center justify-start"
      >
        <!-- User Dropdown -->
        <BaseUserDropdown
          v-if="isLoggedIn"
        />
        <!-- User Dropdown Ends -->
        <div
          class="flex flex-row items-center justify-center"
          v-else
        >
          <nuxt-link
            :to="{ name: 'login' }"
            class="mx-2 px-2 hover:text-a-primary font-semibold"
          >
            Login
          </nuxt-link>
          <span class="text-2xl"
            >/</span
          >
          <nuxt-link
            :to="{ name: 'register' }"
            class="mx-2 px-2 hover:text-a-primary font-semibold"
          >
            Register
          </nuxt-link>
        </div>
      </div>
      <!-- Right Extra Section Ends -->
    </div>
  </div>
</template>

<script setup lang="ts">
const isLoggedIn = computed(()=>{
  return useAuthManager().isLoggedIn.value
})

</script>

<style></style>
