<template>
  <div
    class="flex flex-col justify-center items-center w-full m-0 p-0 top-0 bg-transparent shadow"
  >
    <!-- Top Bar -->
    <div
      class="flex flex-col items-start justify-start w-full h-144 relative"
    >
      <HomeMainCarousel
        class="min-h-full h-128"
      />
      <!-- Transparent Layout Background -->
      <div
        class="flex flex-none bg-a-neutral-dark h-128 min-h-full w-full absolute z-40 bg-opacity-25"
      ></div>
      <!-- Transparent Layout Background Ends -->
      <div
        class="flex flex-col h-128 min-h-full w-full absolute z-50"
      >
        <HomeNavTop />
        <!-- Overlay Section -->
        <div
          class="flex flex-col items-center justify-center h-112 text-white bg-transparent"
        >
          <div
            class="flex flex-row flex-wrap items-end justify-center mb-1"
          >
            <NuxtImg
              src="/images/main/logo_inv.png"
              class="w-48"
              alt=""
            />
            <p class="text-xl md:text-3xl mx-2 text-center">
              is Awesome! Find
              everything you need in
              your city.
            </p>
          </div>
          <p class="text-base md:text-xl mb-2 mx-2 text-center">
            Businesses, Islamic Centers,
            Classified Items,
            Fundraisers & News
          </p>
          <HomeInstantSearch
            class="max-w-sm md:max-w-4xl"
          />
          <div
            class="hidden md:flex flex-row items-center justify-center w-full mt-4"
          >
            <HomeHeroQuickActionLinkButton
              icon="lucide:store"
              text="List My Business"
              link-route-name="dashboard-businesses-create-new"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="streamline:interface-favorite-star-reward-rating-rate-social-star-media-favorite-like-stars"
              text="Write Review"
              link-route-name="businesses"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="material-symbols:post-add"
              text="Add Classified Listing"
              link-route-name="dashboard-classified-create-new"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="mingcute:announcement-line"
              text="Make an Announcement"
              link-route-name="dashboard-news-create-new"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="mdi:charity"
              text="Start a Fundraiser"
              link-route-name="dashboard-fundraising-create-new"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="ph:heart"
              text="Make a Donation"
              link-route-name="fundraising"
              class="mx-1"
            />
          </div>

          <div
            class="hidden md:flex flex-row items-center justify-center w-full mt-4"
          >
            <HomeHeroQuickActionLinkButton
              icon="fluent-emoji-high-contrast:hammer-and-wrench"
              text="Find Best Products and Services"
              link-route-name="businesses"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="material-symbols:manage-search"
              text="Find Classified Items"
              link-route-name="classified"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="mdi:crowd"
              text="Explore Fundraisers"
              link-route-name="fundraising"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="cil:newspaper"
              text="Latest News"
              link-route-name="news-index"
              class="mx-1"
            />
          </div>
          <div
            class="hidden md:flex flex-row items-center justify-center w-full mt-4"
          >
            <HomeHeroQuickActionLinkButton
              icon="emojione-monotone:mosque"
              text="Find Islamic Centers & Madrasahs"
              link-route-name="islamic-centers"
              class="mx-1"
            />
            <HomeHeroQuickActionLinkButton
              icon="material-symbols:prayer-times"
              text="Prayer Timing"
              link-route-name="islamic-centers"
              class="mx-1"
            />
          </div>
        </div>
        <!-- Overlay Section Ends -->
      </div>
    </div>
    <!-- Top Bar Ends-->
    <!-- Main Classified Alert -->
    <BaseAlert
      alignText="center"
      class="z-40"
    />
    <!-- Main Classified Alert Ends -->
  </div>
</template>

<script></script>
