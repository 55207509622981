<template>
  <div
    class="flex flex-row w-full items-center text-a-neutral-darkest shadow-sm"
  >
    <div
      class="flex flex-col w-6/12 md:w-5/12 relative h-10"
    >
      <HomeInstantSearchQuerySection
        ref="querySection"
        @searchClassifiedBySelectedCategory="
          (classifiedCategory) =>
            searchBySelectedClassifiedCategory(
              classifiedCategory
            )
        "
        @searchNewsBySelectedCategory="
          (newsCategory) =>
            searchBySelectedNewsAnnouncementCategory(
              newsCategory
            )
        "
        @searchNewsBySelectedTag="
          (newsTag) =>
            searchBySelectedNewsAnnouncementTag(
              newsTag
            )
        "
        @searchBusinessesBySelectedCategory="
          (businessCategory) =>
            searchBySelectedBusinessCategory(
              businessCategory
            )
        "
        @searchBusinessesBySelectedProduct="
          (businessProduct) =>
            searchBySelectedBusinessProduct(
              businessProduct
            )
        "
        @searchBusinessesBySelectedService="
          (businessService) =>
            searchBySelectedBusinessService(
              businessService
            )
        "
        @searchBusinessesBySelectedcustom-products="
          (businessCustomProduct) =>
            searchBySelectedBusinessCustomProduct(
              businessCustomProduct
            )
        "
        @searchBusinessesBySelectedCustomService="
          (businessCustomService) =>
            searchBySelectedBusinessCustomService(
              businessCustomService
            )
        "
        @searchBusinessesBySelectedAmenity="
          (businessAmenity) =>
            searchBySelectedBusinessAmenity(
              businessAmenity
            )
        "
        @searchFundraisingBySelectedCategory="
          (
            selectedFundraisingCategorySuggestion
          ) =>
            searchBySelectedFundraisingCampaignCategory(
              selectedFundraisingCategorySuggestion
            )
        "
      />
    </div>
    <div
      class="hidden md:flex flex-row items-center justify-center text-center w-2/12 border border-a-neutral-dim border-l-0 border-r-0 bg-white h-10 text-a-neutral-lightest"
    >
      <span class="font-semibold"
        >Around</span
      >
      <Icon
        name="material-symbols:location-on-outline"
      />
    </div>
    <div
      class="flex flex-col w-5/12 md:w-4/12 relative h-10"
    >
      <InstantSearchLocationSection
        ref="locationSectionRef"
        @searchWithNewlySetLocation="
          searchBySelectedLocation
        "
        @searchFromLocation="
          gotoSearchPage()
        "
      />
    </div>
    <BaseButton
      primary-color-class="a-primary"
      text-color-class="a-neutral-darkest"
      text-size-class="text-xl h-10"
      borderRadiusClass="rounded-r rounded-l-none"
      width-class="w-1/12"
      @click="gotoSearchPage()"
      :loading="isSearching"
      loadingColor="#000000"
      loadingSize="7px"
    >
      <Icon
        name="ic:outline-search"
        text="text-a-neutral-darkest text-"
      />
    </BaseButton>
  </div>
</template>

<script setup>
const searchStore = useSearch();
const homeStore = useHome();
const router = useRouter();
const route = useRoute();
const locationSectionRef = ref(null);

const isSearching = computed(() => {
  return searchStore.is_searching;
});
const currentSearchLocation = computed(
  () => {
    return searchStore.current_search_location;
  }
);
const currentSearchQuery = computed(
  () => {
    return searchStore.current_search_query;
  }
);
const defaultClassifiedQueryParams =
  computed(() => {
    searchStore.getClassifiedDefaultQueryParams();
  });

const defaultNewsQueryParams = computed(
  () => {
    searchStore.getNewsDefaultQueryParams();
  }
);

const defaultBusinessesQueryParams =
  computed(() => {
    return searchStore.getBusinessesDefaultQueryParams();

  });

const defaultFundraisingQueryParams =
  computed(() => {
    return searchStore.getFundraisingDefaultQueryParams();
  });

async function gotoNewsSearchPage() {
  initiateSearch();
  await locationSectionRef.adjustLocation();
  await router
    .replace({
      name: "news-announcements-search",
      query: {
        q:
          searchQuery.value == ""
            ? null
            : searchQuery.value,
        ...defaultNewsQueryParams.value,
      },
    })
    .catch((error) => {
      if (
        error.name ===
        "NavigationDuplicated"
      ) {
        homeStore.isNotSearching();
      }
    });
}
function searchBySelectedNewsAnnouncementCategory(
  category
) {
  initiateSearch();
  navigateTo({
    name: "news-announcements-search",
    query: {
      c: category.title,
      ci: category.id,
      ...defaultNewsQueryParams.value,
    },
  });
}

function searchBySelectedNewsAnnouncementTag(
  tag
) {
  initiateSearch();
  navigateTo({
    name: "news-announcements-search",
    query: {
      t: tag.title,
      ti: tag.id,
      ...defaultNewsQueryParams.value,
    },
  });
}

async function gotoBusinessesSearchPage() {
  initiateSearch();
  await locationSectionRef.value?.adjustLocation();
  await navigateTo({
    name: "businesses-search",
    query: {
      q: currentSearchQuery.value == "" ? null : currentSearchQuery.value,
      ...searchStore.getBusinessesDefaultQueryParams(),
    },
  })
}

function searchBySelectedBusinessCategory(
  category
) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      c: category.title,
      ci: category.id,
      ...defaultBusinessesQueryParams.value,
    },
  });
}

function searchBySelectedBusinessProduct(
  product
) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bp: product.title,
      ...defaultBusinessesQueryParams.value,
    },
  });
}

function searchBySelectedBusinessService(
  service
) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bs: service.title,
      ...defaultBusinessesQueryParams.value,
    },
  });
}

function searchBySelectedBusinessCustomProduct(
  customProduct
) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bcp: customProduct.title,
      ...defaultBusinessesQueryParams.value,
    },
  });
}

function searchBySelectedBusinessCustomService(
  customService
) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      bcs: customService.title,
      ...defaultBusinessesQueryParams.value,
    },
  });
}

function searchBySelectedBusinessAmenity(
  amenity
) {
  initiateSearch();
  navigateTo({
    name: "businesses-search",
    query: {
      am: amenity.title,
      ...defaultBusinessesQueryParams,
    },
  });
}

async function gotoFundraisingSearchPage() {
  initiateSearch();
  if (useRoute().name === 'fundraising-search') {
    await router.replace({
      query: {
        q: localSearchQuery.value == "" ? "" : localSearchQuery.value,
        ...searchStore.getFundraisingDefaultQueryParams(),
      },
    })
  } else {
    await navigateTo({
      name: "fundraising-search",
      query: {
        q: localSearchQuery.value == "" ? "" : localSearchQuery.value,
        ...searchStore.getFundraisingDefaultQueryParams(),
      },
    })
  }
}

function searchBySelectedFundraisingCampaignCategory(
  category
) {
  localSearchQuery.value =
    category.title;
  initiateSearch();
  navigateTo({
    name: "fundraising-search",
    query: {
      c: category.title,
      ci: category.id,
      ...defaultFundraisingQueryParams.value,
    },
  });
}

async function gotoClassifiedSearchPage() {
  initiateSearch();
  await locationSectionRef.adjustLocation();
  if (useRoute().name === 'classified-search') {
    await useRouter().replace({
      query: {
        q:
            currentSearchQuery.value == ""
                ? null
                : currentSearchQuery.value,
        ...searchStore.getClassifiedDefaultQueryParams(),
      },
    })
  } else {
    await navigateTo({
      name: "classified-search",
      query: {
        q:
            currentSearchQuery.value == ""
                ? null
                : currentSearchQuery.value,
        ...searchStore.getClassifiedDefaultQueryParams(),
      },
    })
  }
  doneSearching();
}

function searchBySelectedClassifiedCategory(
  category
) {
  initiateSearch();
  navigateTo({
    name: "classified-search",
    query: {
      c: category.title,
      ci: category.id,
      ...defaultClassifiedQueryParams.value,
    },
  });
}

function searchBySelectedLocation() {
  initiateSearch();
  router.replace({
    name: "search",
    query: {
      ...route.query,
      q: currentSearchQuery.value,
      ...defaultClassifiedQueryParams.value,
      page: undefined,
    },
  });
}

function initiateSearch() {
  homeStore.isSearching();
  homeStore.hideSearchSuggestions();
}

function doneSearching(){
  searchStore.isNotSearching();
}

watch(
  route,
  (to, from) => {
    homeStore.isNotSearching();
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<style></style>
