<template>
  <div class="swiper homeMainCarouselSwiper w-full h-144">
    <div
        class="swiper-wrapper"
    >
      <div
          class="swiper-slide"
          v-for="banner in banners"
          :key="banner"
      >
        <NuxtImg
            :src="banner"
            alt=""
            class="w-full object-cover bg-bottom h-144"
        />
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
import {Autoplay, EffectFade} from "swiper/modules";

const banners = ref([
  "/images/home/home1.jpg",
  "/images/home/home2.jpg",
  "/images/home/home3.jpg",
  "/images/home/home4.jpg",
  "/images/home/home5.jpg",
]);

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.homeMainCarouselSwiper', {
    modules: [Autoplay, EffectFade],
    effect: 'fade',
    autoplay: {
      delay: 7000,
    },
    loop: true,
    allowTouchMove: "false",
    observer: true
  });
})

</script>

<style></style>
