<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center justify-between">
    <p
      class="text-start text-xl md:text-2xl font-semibold mt-2 mb-4"
    >
      Fundraisers
    </p>
      <nuxt-link
        :to="{
        name:'fundraising'
        }"
        class="text-sm"
      >
        View More
      </nuxt-link>
    </div>
    <div
        v-if="fundraisingCampaigns.length>0"
      class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full items-stretch justify-center"
    >
      <HomeFundraisingCampaignCard
        style="
          min-height: 300px;
          height: auto;
        "
        v-for="campaign in fundraisingCampaigns"
        :key="campaign.slug"
        :fundraising-campaign="campaign"
        @click="
          gotoFundraisingCampaignPage(
            campaign
          )
        "
      />
    </div>
    <div
        v-else
        class="flex flex-col"
    >
      <BaseNoDataSection heading="Fundraisers">
        <template v-slot:content>
          <p>
            No Fundraiser Exists Yet!
          </p>
        </template>
      </BaseNoDataSection>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {HomeFundraisingCampaign} from "~/types/main";

const props = withDefaults(defineProps<{
  fundraisingCampaigns: HomeFundraisingCampaign[]
}>(),{})
function gotoFundraisingCampaignPage(
    campaign
) {
  navigateTo({
    name: "fundraising-slug",
    params: {
      slug: campaign.slug,
    },
  });
}
</script>

<style></style>
