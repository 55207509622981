<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center justify-between">

      <p
          class="text-start text-xl md:text-2xl font-semibold mt-2 mb-4"
      >
        Businesses
      </p>
      <nuxt-link
          :to="{
        name:'businesses'
        }"
          class="text-sm"
      >
        View More
      </nuxt-link>
    </div>
    <div
        v-if="businesses.length>0"
        class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full"
    >
      <BusinessCard
          class="w-full my-3"
          style="
          min-height: 250px;
          height: auto;
        "
          v-for="business in businesses"
          :key="business.slug"
          :business="business"
          @click="
          gotoBusinessPage(business)
        "
      />
    </div>
    <div
        v-else
        class="flex flex-col"
    >
      <BaseNoDataSection heading="Business Listings">
        <template v-slot:content>
          <p>
            No Business Listing Exists Yet!
          </p>
        </template>
      </BaseNoDataSection>
    </div>
  </div>
</template>
<script
    setup
    lang="ts"
>
import type {HomeBusiness} from "~/types/main";

const props = withDefaults(defineProps<{
  businesses: HomeBusiness[]
}>(), {})

function gotoBusinessPage(business) {
  navigateTo({
    name: "businesses-slug",
    params: {slug: business.slug},
  });
}

</script>

<style></style>
