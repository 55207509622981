<template>
  <BaseButton
    primary-color-class="a-primary"
    text-color-class="a-neutral-darkest"
    text-size-class="text-sm"
    width-class="w-auto"
    @click="gotoActionLink()"
  >
    <div class="flex flex-row items-center">
      <Icon :name="icon as string" class="mx-0.5" />
      <p>{{ text }}</p>
    </div>
  </BaseButton>
</template>
<script setup lang="ts">
const props = defineProps({
  linkRouteName: {
    type: String,
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
  text: {
    type: String,
    required: true,
  },
});

function gotoActionLink() {
  navigateTo({
    name: props.linkRouteName,
  });
}
</script>
