<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center justify-between">
    <p
      class="text-start text-xl md:text-2xl font-semibold mt-2 mb-4"
    >
      Classified Listings
    </p>
      <nuxt-link
          :to="{
        name:'classified'
        }"
          class="text-sm"
      >
        View More
      </nuxt-link>
    </div>
    <div
        v-if="classifiedAds.length>0"
        class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full items-stretch justify-center"
    >
      <HomeClassifiedAdCard
        style="
          min-height: 300px;
          height: auto;
        "
        v-for="classifiedAd in classifiedAds"
        :key="classifiedAd.slug"
        :classified-ad="classifiedAd"
        @click="
          gotoClassifiedAdPage(
            classifiedAd
          )
        "
      />
    </div>
    <div v-else class="flex flex-col">
      <BaseNoDataSection heading="Classified Ads">
        <template v-slot:content>
          <p>
            No Classified Listing Exists Yet!
          </p>
        </template>
      </BaseNoDataSection>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {HomeClassifiedAd} from "~/types/main";

const props = withDefaults(defineProps<{
  classifiedAds: HomeClassifiedAd[]
}>(),{})

function gotoClassifiedAdPage(ad) {
  navigateTo({
      name: "classified-slug",
      params: { slug: ad.slug },
  });
}
</script>

<style></style>
