
export const useHomeDataManager = () => {
    const getHomeData = async () => {
        return await useHomeDataService().fetchHomeData();
    }



    return {
        getHomeData
    }
}