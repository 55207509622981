<template>
  <div
      class="flex flex-col items-start justify-center rounded-md mx-2 hover:bg-white h-full cursor-pointer pb-2"
      :class="[
      newsAnnouncement.is_featured ? 'border-a-primary border-2 hover:shadow-lg' : 'border border-transparent hover:border-a-neutral-dim hover:shadow-md',
    ]"
      @click="
      navigateTo({
        name: 'news-announcements-slug',
        params: { slug: newsAnnouncement.slug },
      })
    "
  >
    <div class="relative w-full">
      <NuxtImg
          :src="newsAnnouncement.images[0]"
          class="object-cover h-40 w-full"
          :class="newsAnnouncement.is_featured
            ? ''
            : 'rounded-t-md'"
          alt=""
      />
      <span
          v-if="newsAnnouncement.is_featured"
          class="bg-a-primary text-a-neutral-darkest p-1 text-xs rounded-t-none rounded-bl-none rounded-br text-center absolute left-0 top-0"
      >
        <Icon name="mdi:fire"/>
        Important
      </span>
    </div>
    <div class="flex flex-1 flex-col w-full px-2">
      <p class="font-semibold my-1">{{ excerptedTitle }}</p>
      <div class="flex flex-row items-center text-a-neutral-light text-sm">
        <NuxtImg
            v-if="newsAnnouncement.user.avatar"
            :src="newsAnnouncement.user.avatar"
            class="object-cover h-8 w-8 rounded-full"
        />
        <Icon
            v-else
            name="material-symbols:person"
        />
        &nbsp;
        <p class="text-sm">{{ newsAnnouncement.user.full_name }}</p>
      </div>
      <div class="flex flex-row items-center font-semibold text-a-neutral-lightest justify-start p-1">
        <Icon
            name="mdi:map-marker"
            class="text-xl text-a-error-dark"
        />
        <div class="flex flex-row items-center text-xs text-a-neutral-light">
          &nbsp;
          <p>{{ newsAnnouncement.city }},</p>
          &nbsp;
          <p>{{ newsAnnouncement.state }}</p>
        </div>
      </div>
      <div class="flex flex-row items-center justify-end font-semibold text-xs text-a-neutral-light">
        {{
          newsAnnouncement.published_at_human_formatted
        }}
        <BaseTooltip
            trigger-icon="material-symbols:info-rounded"
            :tooltipText="newsAnnouncement.published_at"
        />
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
import type {HomeNewsAnnouncement} from "~/types/main";

const props = withDefaults(defineProps<{
  newsAnnouncement: HomeNewsAnnouncement
}>(), {})


const excerptedTitle = computed(()=>{
  if(props.newsAnnouncement.title.length <= 70){
    return props.newsAnnouncement.title
  }
  return (props.newsAnnouncement.title as string).slice(0,70)+'...';
})

</script>

<style></style>
