<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center justify-between">
      <p
          class="text-start text-xl md:text-2xl font-semibold mt-2 mb-4"
      >
        Islamic Centers
      </p>
      <nuxt-link
          :to="{
        name:'islamic-centers'
        }"
          class="text-sm"
      >
        View More
      </nuxt-link>
    </div>
    <div
        v-if="islamicCenters.length>0"
        class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full items-stretch justify-center"
    >
      <HomeIslamicCenterCard
          style="
          min-height: 300px;
          height: auto;
        "
          v-for="islamicCenter in islamicCenters"
          :key="islamicCenter.slug"
          :islamic-center="islamicCenter"
          @click="
          gotoIslamicCenterPage(
            islamicCenter
          )
        "
      />
    </div>
    <div
        v-else
        class="flex flex-col"
    >
      <BaseNoDataSection heading="Fundraisers">
        <template v-slot:content>
          <p>
            No Fundraiser Exists Yet!
          </p>
        </template>
      </BaseNoDataSection>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {HomeIslamicCenter} from "~/types";

const props = withDefaults(defineProps<{
  islamicCenters: HomeIslamicCenter[]
}>(),{})
function gotoIslamicCenterPage(
    islamicCenter
) {
  navigateTo({
    name: "islamic-centers-slug",
    params: {
      slug: islamicCenter.slug,
    },
  });
}
</script>

<style></style>
