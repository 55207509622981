import type {HomeData} from "~/types/main";

export const useHomeDataService = () => {
    const nuxtApp = useNuxtApp();
    const fetchHomeData = async () => {
        const { data, error } = await useAsyncData('home-data',
            () => nuxtApp.$getHomeData()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as HomeData;
        }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchHomeData
    }
}