<template>
  <div
    v-click-outside="
      () => {
        classifiedStore.hideSearchSuggestions();
      }
    "
  >
    <!-- Search Query Input -->
    <input
      type="text"
      class="h-10 w-full rounded-l bg-white border border-a-neutral-dim border-r-0 p-2 focus:outline-none font-semibold"
      placeholder="Search For Items..."
      v-model="localSearchQuery"
      @input="getInstantSearchResults"
    />
    <!-- Search Query Input Ends -->
    <!-- Search Suggestions -->
    <div
      :class="[
        'flex flex-col w-full absolute top-10 bg-white border-b-2 border-l-2 border-r-2 rounded-b shadow-xl z-40',
      ]"
      v-if="viewSearchSuggestionsList"
    >
      <BaseInstantSearchSuggestionsList
        v-if="
          classifiedAdCategorySearchSuggestions.length >
          0
        "
        :suggestions="
          classifiedAdCategorySearchSuggestions
        "
        highlightingfield-name="title"
        listTitle="Classified Categories"
        :currentSearchQuery="
          localSearchQuery
        "
        @suggestionSelected="
          (
            selectedCategorySuggestion
          ) =>
            searchSuggestedClassifiedAdCategory(
              selectedCategorySuggestion
            )
        "
      />
      <span
        class="w-full border-b border-a-neutral-dim"
      ></span>
      <BaseInstantSearchSuggestionsList
        v-if="
          classifiedAdSearchSuggestions.length >
          0
        "
        :suggestions="
          classifiedAdSearchSuggestions
        "
        highlightingfield-name="title"
        listTitle="Classified Listings"
        :currentSearchQuery="
          localSearchQuery
        "
        @suggestionSelected="
          (selectedListingSuggestion) =>
            gotoSuggestedClassifiedAdPage(
              selectedListingSuggestion
            )
        "
      />
      <span
        class="w-full border-b border-a-neutral-dim"
      ></span>

      <span
        class="w-full border-b border-a-neutral-dim"
      ></span>
      <BaseInstantSearchSuggestionsList
        v-if="
          fundraisingCampaignCategorySearchSuggestions.length >
          0
        "
        :suggestions="
          fundraisingCampaignCategorySearchSuggestions
        "
        highlightingfield-name="title"
        listTitle="Fundraising Categories"
        :currentSearchQuery="
          localSearchQuery
        "
        @suggestionSelected="
          (
            selectedCategorySuggestion
          ) =>
            searchSuggestedFundraisingCampaignCategory(
              selectedCategorySuggestion
            )
        "
      />
      <span
        class="w-full border-b border-a-neutral-dim"
      ></span>
      <BaseInstantSearchSuggestionsList
        v-if="
          fundraisingCampaignSearchSuggestions.length >
          0
        "
        :suggestions="
          fundraisingCampaignSearchSuggestions
        "
        highlightingfield-name="title"
        listTitle="Fundraisers"
        :currentSearchQuery="
          localSearchQuery
        "
        @suggestionSelected="
          (
            selectedCampaignSuggestion
          ) =>
            gotoSuggestedFundraisingCampaignPage(
              selectedCampaignSuggestion
            )
        "
      />
      <BaseInstantSearchSuggestionsList
        v-if="
          newsAnnouncementCategorySearchSuggestions.length >
          0
        "
        :suggestions="
          newsAnnouncementCategorySearchSuggestions
        "
        highlightingfield-name="title"
        listTitle="Categories"
        :currentSearchQuery="
          localSearchQuery
        "
        @suggestionSelected="
          (
            selectedCategorySuggestion
          ) =>
            searchSuggestedNewsAnnouncementCategory(
              selectedCategorySuggestion
            )
        "
      />
      <span
        class="w-full border-b border-a-neutral-dim"
      ></span>
      <!-- News Announcement Tags Suggestions -->
      <BaseInstantSearchSuggestionsList
        v-if="
          newsAnnouncementTagSearchSuggestions.length >
          0
        "
        :suggestions="
          newsAnnouncementTagSearchSuggestions
        "
        highlightingfield-name="title"
        listTitle="Tags"
        :currentSearchQuery="
          localSearchQuery
        "
        @suggestionSelected="
          (selectedTagSuggestion) =>
            searchSuggestedNewsAnnouncementTag(
              selectedTagSuggestion
            )
        "
      />
      <span
        class="w-full border-b border-a-neutral-dim"
      ></span>
      <!--News Announcement Suggestions -->
      <BaseInstantSearchSuggestionsList
        v-if="
          newsAnnouncementSearchSuggestions.length >
          0
        "
        :suggestions="
          newsAnnouncementSearchSuggestions
        "
        highlightingfield-name="title"
        listTitle="News Announcements"
        :currentSearchQuery="
          localSearchQuery
        "
        @suggestionSelected="
          (
            selectedAnnouncementSuggestion
          ) =>
            gotoSuggestedNewsAnnouncementPage(
              selectedAnnouncementSuggestion
            )
        "
      />
    </div>
  </div>
  <!-- Search Suggestions Div Ends -->
</template>

<script setup>
const classifiedStore = useClassified();
const homeStore = useHome();
const router = useRouter();

defineEmits([
  "searchBySelectedCategory",
]);

const localSearchQuery = ref("");
const classifiedAdCategorySearchSuggestions =
  ref([]);
const classifiedAdSearchSuggestions =
  ref([]);
const fundraisingCampaignCategorySearchSuggestions =
  ref([]);
const fundraisingCampaignSearchSuggestions =
  ref([]);
const businessCategorySearchSuggestions =
  ref([]);
const businessSearchSuggestions = ref(
  []
);
const businessProductSearchSuggestions =
  ref([]);
const businessServiceSearchSuggestions =
  ref([]);
const businessCustomProductSearchSuggestions =
  ref([]);
const businessCustomServiceSearchSuggestions =
  ref([]);
const businessAmenitySearchSuggestions =
  ref([]);
const newsAnnouncementCategorySearchSuggestions =
  ref([]);
const newsAnnouncementTagSearchSuggestions =
  ref([]);
const newsAnnouncementSearchSuggestions =
  ref([]);

function searchSuggestedClassifiedAdCategory(
  category
) {
  classifiedStore.hideSearchSuggestions();
  localSearchQuery.value =
    category.title;
  classifiedStore.isSearching();
  emit(
    "searchBySelectedCategory",
    category
  );
}

async function getInstantSearchResults() {
  if (
    localSearchQuery.value !== "" &&
    localSearchQuery.value.length > 1
  ) {
    homeStore.showSearchSuggestions();
    const { data, pending, error } =
      await useNuxtApp().$getInstantSearchResultsForHomeByQuery(
        localSearchQuery.value
      );
    if (error.value != null) {
      showError({
        statusMessage:
          "Something wrong",
      });
    } else {
      classifiedAdCategorySearchSuggestions.value =
        data.value.classifiedInstantSearchResults.classifiedAdCategoriesResults;
      classifiedAdSearchSuggestions.value =
        data.value.classifiedInstantSearchResults.classifiedAdResults;
      fundraisingCampaignCategorySearchSuggestions.value =
        data.value.fundraisingInstantSearchResults.fundraisingCampaignCategoriesResults;
      fundraisingCampaignSearchSuggestions.value =
        data.value.fundraisingInstantSearchResults.fundraisingCampaignResults;
      businessSearchSuggestions.value =
        data.value.businessesInstantSearchResults.businessResults;
      businessCategorySearchSuggestions.value =
        data.value.businessesInstantSearchResults.businessCategoriesResults;
      businessProductSearchSuggestions.value =
        data.value.businessesInstantSearchResults.businessProductsResults;
      businessServiceSearchSuggestions.value =
        data.value.businessesInstantSearchResults.businessServicesResults;
      businessCustomProductSearchSuggestions.value =
        data.value.businessesInstantSearchResults.businessCustomProductsResults;
      businessCustomServiceSearchSuggestions.value =
        data.value.businessesInstantSearchResults.businessCustomServicesResults;
      businessAmenitySearchSuggestions.value =
        data.value.businessesInstantSearchResults.businessAmenitiesResults;
      newsAnnouncementCategorySearchSuggestions.value =
        data.value.newsInstantSearchResults.newsAnnouncementCategoriesResults;
      newsAnnouncementTagSearchSuggestions.value =
        data.value.newsInstantSearchResults.newsAnnouncementTagsResults;
      newsAnnouncementSearchSuggestions.value =
        data.value.newsInstantSearchResults.newsAnnouncementResults;
    }
  } else {
    homeStore.hideSearchSuggestions();
  }
}

function gotoSuggestedBusinessPage(
  business
) {
  localSearchQuery.value = "";
  navigateTo({
    name: "businesses-slug",
    params: {
      slug: business.slug,
    },
  });
}

function gotoSuggestedFundraisingCampaignPage(
  fundraisingCampaign
) {
  localSearchQuery.value = "";
  initiateSearch();
  navigateTo({
    name: "fundraising-slug",
    params: {
      slug: fundraisingCampaign.slug,
    },
  });
}

function gotoSuggestedNewsAnnouncementPage(
  newsAnnouncement
) {
  searchQuery.value = "";
  navigateTo({
    name: "news-announcements-slug",
    params: {
      slug: newsAnnouncement.slug,
    },
  });
}

function gotoSuggestedClassifiedAdPage(
  classifiedAd
) {
  localSearchQuery.value = "";
  navigateTo({
    name: "classified-slug",
    params: {
      slug: classifiedAd.slug,
    },
  });
}

const viewSearchSuggestionsList =
  computed(() => {
    return (
      homeStore.is_showing_search_suggestions &&
      (classifiedAdCategorySearchSuggestions
        .value.length > 0 ||
        classifiedAdSearchSuggestions
          .value.length > 0 ||
        fundraisingCampaignCategorySearchSuggestions
          .value.length > 0 ||
        fundraisingCampaignSearchSuggestions
          .value.length > 0 ||
        businessCategorySearchSuggestions
          .value.length > 0 ||
        businessSearchSuggestions.value
          .length > 0 ||
        businessProductSearchSuggestions
          .value.length > 0 ||
        businessServiceSearchSuggestions
          .value.length > 0 ||
        businessCustomProductSearchSuggestions
          .value.length > 0 ||
        businessCustomServiceSearchSuggestions
          .value.length > 0 ||
        businessAmenitySearchSuggestions
          .value.length > 0 ||
        newsAnnouncementCategorySearchSuggestions
          .value.length > 0 ||
        newsAnnouncementTagSearchSuggestions
          .value.length > 0 ||
        newsAnnouncementSearchSuggestions
          .value.length > 0)
    );
  });
</script>

<style></style>
